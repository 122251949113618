import React from "react"
import { Link } from "gatsby"

const SolutionsSidebar = () => (
  <div className="solutions-sidebar mt-5 mt-md-0">
    <ul>
      <li>
        <Link to="/managed-services/">Managed Services</Link>
      </li>
      <li>
        <Link to="/cloud-it-solutions/">Cloud IT Solutions</Link>
      </li>
      <li>
        <Link to="/help-desk/">Help Desk</Link>
      </li>
      <li>
        <Link to="/voip-and-connectivity/">Hosted VoIP and Connectivity</Link>
      </li>
      <li>
        <Link to="/network-cabling/">Network Cabling</Link>
      </li>
      <li>
        <Link to="/special-projects/">Special Projects</Link>
      </li>
      <li>
        <Link to="/file-sync-and-share/">File Sync and Share</Link>
      </li>
      <li>
        <Link to="/backup-and-disaster-recovery/">
          Backup and Disaster Recovery
        </Link>
      </li>
      <li>
        <Link to="/web-solutions/">Web Solutions</Link>
      </li>
    </ul>
  </div>
)

export default SolutionsSidebar
