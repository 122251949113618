import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"
const SpecialProjects = ({ data }) => (
  <Layout>
    <Seo
      title="File Sync and Share"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>File Sync and Share</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              File sync and share provides your company with the ability to
              access, edit, and share files from your smartphone, tablet, or any
              computer.
            </p>
            <p>
              This has become a growing need for companies as many employees and
              employers work in different offices and all over the country. Not
              only does this allow for teams to collaborate together and view
              files as they are in progress, as well as access important files
              from any device you have with you.
            </p>
            <p>
              With the ability to access the files that you want, where you
              want, businesses are provided with the following:
            </p>
            <ul>
              <li>Security</li>
              <li>Easy access to files</li>
              <li>Easy sharing between colleagues</li>
              <li>Quick solutions</li>
            </ul>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SpecialProjects

export const SpecialProjectsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
